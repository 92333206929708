import { API_DOMAIN } from '../constants'
import { NotificationsClass } from './NotificationsClass'
import { notifyDown, notifyUp } from './ServerDownManager'
const requestBuckets = {
  background: [] as (() => Promise<void>)[],
  important: [] as (() => Promise<void>)[],
  bulkActive: [] as (() => Promise<void>)[],
  default: [] as (() => Promise<void>)[],
}
const bucketConfigs = {
  background: {
    concurrency: 1,
    timeout: 0,
  },
  important: {
    concurrency: 200,
    timeout: 0,
  },
  bulkActive: {
    concurrency: 5,
    timeout: 0,
  },
  default: {
    concurrency: 2,
    timeout: 0,
  },
}
export const fetcher = (
  input: RequestInfo,
  init?: RequestInit | undefined,
  btype: keyof typeof requestBuckets = 'default',
  withAuth = true
) => {
  btype = btype ?? 'default'
  //   console.log('[fetcher]', input, init, btype))
  return new Promise<Response>((resolve, reject) => {
    const func = async () => {
      const fet = fetch(input, {
        ...init,
        headers: {
          'Content-Type': 'application/json',
          ...(init?.headers || {}),
          ...(withAuth
            ? {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              }
            : {}),
        },
      })
      Promise.race([fet, new Promise(res2 => setTimeout(() => res2(false), 5000))]).then(res => {
        if (!res) {
          fetch(`${API_DOMAIN}/ping`).then(r => {
            !r.ok && notifyDown()
          })
        }
      })

      return fet
        .then(r => {
          // if server is down, r.status will be 521
          if (r.status === 521) {
            notifyDown()
          }
          if (~~(r.status / 100)) {
            notifyUp()
          }

          return r
        })
        .catch(e => {
          if (e) console.log('SERVER IS DOWN', e)
          notifyDown()
          return e
        })
        .then(resolve)
        .catch(er => {
          // if i get a 401 error, remove the token and reload the page
          if (
            er.status === 401 &&
            (input.toString().startsWith('https://api.disadus.app') ||
              input.toString().startsWith('http://localhost:443'))
          ) {
            localStorage.removeItem('token')
          }
          reject(er)
        })
    }
    //   console.log('Requesting', input, btype, requestBuckets))
    requestBuckets[btype!].push(func)
    FetchFufiller.self.init()
  })
}
class FetchFufiller {
  static self = new FetchFufiller()
  runningBackgroundRequests = false
  runningImportantRequests = false
  runningDefaultRequests = false
  runningBulkRequests = false
  constructor() {
    this.init()
  }
  init() {
    if (!this.runningBackgroundRequests) {
      this.runningBackgroundRequests = true
      this.handleBackgroundBucket()
    }
    if (!this.runningImportantRequests) {
      this.runningImportantRequests = true
      this.handleImportantBucket()
    }
    if (!this.runningDefaultRequests) {
      this.runningDefaultRequests = true
      this.handleDefaultBucket()
    }
    if (!this.runningBulkRequests) {
      this.runningBulkRequests = true
      this.handleBulkBucket()
    }
  }
  async handleBackgroundBucket() {
    while (requestBuckets.background.length) {
      const items = requestBuckets.background.splice(0, bucketConfigs.background.concurrency)
      await Promise.all(items.map(item => item()))
    }
    this.runningBackgroundRequests = false
  }
  async handleImportantBucket() {
    while (requestBuckets.important.length) {
      const items = requestBuckets.important.splice(0, bucketConfigs.important.concurrency)
      await Promise.all(items.map(item => item()))
    }
    this.runningImportantRequests = false
  }
  async handleBulkBucket() {
    while (requestBuckets.bulkActive.length) {
      const items = requestBuckets.bulkActive.splice(0, bucketConfigs.bulkActive.concurrency)
      await Promise.all(items.map(item => item()))
    }
    this.runningBulkRequests = false
  }
  async handleDefaultBucket() {
    while (requestBuckets.default.length) {
      const items = requestBuckets.default.splice(0, bucketConfigs.default.concurrency)
      await Promise.all(items.map(item => item()))
    }
    this.runningDefaultRequests = false
  }
}
