import { NotificationsClass } from './NotificationsClass'

let down = false
export const notifyDown = () => {
  if (down) return
  NotificationsClass.getInstance().addNotif({
    title: 'Unable to connect to server',
    message:
      'We are unable to connect to the server. The server may be down or you may have lost your internet connection.',
    type: 'error',
    duration: 10000,
  })
  down = true
}
export const notifyUp = () => {
  if (!down) return
  NotificationsClass.getInstance().addNotif({
    title: 'Connection to server restored',
    message: 'The server is back online.',
    type: 'success',
    duration: 10000,
  })
  down = false
}
