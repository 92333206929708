import { useState, useEffect, useLayoutEffect } from 'react'
import { fetcher } from '../../classes/Fetcher'
import { UserCourses } from '../../classes/UserCourses'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { Course } from '../../types/courseTypes'
import { useUserCourses } from './useUserCourses'

export const useCourse = (courseID?: string, cachable: boolean = true, important: boolean = false) => {
  const userCourses = useUserCourses()
  //   console.log('useCourse:', courseID, userCourses))
  const [fetchCourse, setFetchCourse] = useState(userCourses?.get('_all')?.get(courseID!) as null | undefined | Course)
  useEffect(() => {
    //
    let cancel = false
    if (!courseID) return
    UserCourses.getInstance()
      .getCourse(courseID, !cachable)
      .then(course => {
        if (!cancel) {
          if (course) return setFetchCourse(course)
          setFetchCourse({
            name: 'Unavailable Course',
            teacher: 'Unavailable',
            _id: 'unavailable',
            id: courseID,
            communityID: 'unavailable',
            icon: '',
            integrationType: 'unavailable',
            integrationSnowflake: 'unavailable',
            members: [],
            period: 'unavailable',
            membersObjects: [],
          })
        }
      })
    const courseUpdate = (course: Course) => {
      if (course.id === courseID) {
        setFetchCourse(course)
      }
    }
    UserCourses.getInstance().addListener('courseUpdate', courseUpdate)
    
    return () => {
      cancel = true
      UserCourses.getInstance().removeListener('courseUpdate', courseUpdate)
    }
  }, [courseID, cachable, userCourses, important])
  if (!courseID || !fetchCourse) return null
  return fetchCourse || userCourses?.get(courseID)
}
