import EventEmitter from 'events'
import localforage from 'localforage'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { Assignment, CourseStructure, UpcomingCourseEvent } from '../../types/courseTypes'
import { fetcher } from '../Fetcher'
import { NotificationsClass } from '../NotificationsClass'
import { SocketManager } from '../SocketManager'

const mergeMaterialMetaProps = (material: Assignment | undefined | null, newMaterial: Assignment) => {
  const publicMetaPropOld = material?.metaProps?.find(x => !x.personal)
  const publicMetaPropNew = newMaterial.metaProps?.find(x => !x.personal)
  const privateMetaPropOld = material?.metaProps?.find(x => x.personal)
  const privateMetaPropNew = newMaterial.metaProps?.find(x => x.personal)
  // console.log('mergeMaterialMetaProps', publicMetaPropOld, publicMetaPropNew, privateMetaPropOld, privateMetaPropNew,[
  //   ...(publicMetaPropOld && !publicMetaPropNew ? [publicMetaPropOld] : []),
  //   ...(publicMetaPropNew ? [publicMetaPropNew] : []),
  //   ...(privateMetaPropOld && !privateMetaPropNew ? [privateMetaPropOld] : []),
  //   ...(privateMetaPropNew ? [privateMetaPropNew] : []),
  // ])
  return [
    ...(publicMetaPropOld && !publicMetaPropNew ? [publicMetaPropOld] : []),
    ...(publicMetaPropNew ? [publicMetaPropNew] : []),
    ...(privateMetaPropOld && !privateMetaPropNew ? [privateMetaPropOld] : []),
    ...(privateMetaPropNew ? [privateMetaPropNew] : []),
  ]
}
const mergeMaterial = (material: Assignment | undefined | null, newMaterial: Assignment) =>
  ({
    ...material,
    ...newMaterial,
    metaProps: mergeMaterialMetaProps(material, newMaterial),
  } as Assignment)

export class CourseMaterialsDataClass extends EventEmitter {
  static _instanceMap = new Map<string, CourseMaterialsDataClass>()
  static getInstance(courseID: string, noInit?: boolean) {
    if (!CourseMaterialsDataClass._instanceMap.has(courseID)) {
      CourseMaterialsDataClass._instanceMap.set(courseID, new CourseMaterialsDataClass(courseID, noInit))
    }
    return CourseMaterialsDataClass._instanceMap.get(courseID)!
  }
  courseID: string
  materials = undefined as null | undefined | Map<string, Assignment>
  lastFetch = new Map<string, number>()
  overdue = undefined as null | undefined | Set<string>
  upcomingEvents = undefined as null | undefined | Map<string, UpcomingCourseEvent>
  courseStructure = undefined as null | undefined | CourseStructure

  fetchingUpcoming = false as boolean
  fetchingMaterials = false as boolean
  private constructor(courseID: string, noInit?: boolean) {
    super()
    this.courseID = courseID
    this.init(noInit)
    this.setMaxListeners(1000)
  }

  async init(noFetch?: boolean) {
    this.readFromCache()
    if (noFetch) return
    this.getMaterials()
    this.getOverdueMaterials()
    this.getUpcomingEvents()
    this.getCourseStructure()
    SocketManager.getInstance().on('courseMaterialsResponse', this.onMaterialsUpdate.bind(this))
    SocketManager.getInstance().on('cachedCourseMaterialsResponse', this.onMaterialsUpdate.bind(this))
    SocketManager.getInstance().on('courseOverdueResponse', this.onOverdueUpdate.bind(this))
    SocketManager.getInstance().on('courseUpcomingResponse', this.onUpcomingEventsUpdate.bind(this))
    SocketManager.getInstance().on('courseStructureResponse', this.onCourseStructureUpdate.bind(this))
    SocketManager.getInstance().on('cachedCourseStructureResponse', this.onCourseStructureUpdate.bind(this))
  }
  async writeToCache() {
    ;(await localforage).setItem(`course.${this.courseID}.materials`, this.materials)
    ;(await localforage).setItem(`course.${this.courseID}.overdue`, this.overdue)
    ;(await localforage).setItem(`course.${this.courseID}.upcomingEvents`, this.upcomingEvents)
    ;(await localforage).setItem(`course.${this.courseID}.courseStructure`, this.courseStructure)
  }
  async readFromCache() {
    await localforage.ready()
    let start = performance.now()
    await Promise.all(
      [
        this.readMaterialsFromCache(),
        this.readOverdueMaterialsFromCache(),
        this.readUpcomingEventsFromCache(),
        this.readCourseStructureFromCache(),
      ]
      // .map(x => x.then(() => console.log(`${performance.now() - start}ms`)))
    )
    // console.log(`[CourseMaterialsDataClass] readFromCache: ${performance.now() - start}ms`)
    // this.readFromCache
  }
  async readMaterialsFromCache() {
    const materials = await (await localforage).getItem(`course.${this.courseID}.materials`)
    if (materials) {
      this.materials = materials as Map<string, Assignment>
      this.emit('materialUpdate', {
        materials: this.materials,
        cached: true,
      })
    }
  }
  async readOverdueMaterialsFromCache() {
    const overdue = await (await localforage).getItem(`course.${this.courseID}.overdue`)
    if (overdue) {
      this.overdue = overdue as Set<string>
      this.emit('overdueUpdate', {
        overdue: this.overdue,
        cached: true,
      })
    }
  }
  async readUpcomingEventsFromCache() {
    const upcomingEvents = await (await localforage).getItem(`course.${this.courseID}.upcomingEvents`)
    if (upcomingEvents) {
      this.upcomingEvents = upcomingEvents as Map<string, UpcomingCourseEvent>
      this.emit('upcomingEventsUpdate', {
        upcomingEvents: this.upcomingEvents,
        cached: true,
      })
    }
  }
  async readCourseStructureFromCache() {
    const courseStructure = await (await localforage).getItem(`course.${this.courseID}.courseStructure`)
    if (courseStructure) {
      this.courseStructure = courseStructure as CourseStructure
      this.emit('courseStructureUpdate', {
        courseStructure: this.courseStructure,
        cached: true,
      })
    }
  }

  async onMaterialUpdate(material: Assignment) {
    this.materials!.set(material.id, mergeMaterial(this.materials!.get(material.id), material))
    this.emit('materialUpdate', {
      material,
      cached: false,
    })
  }
  async onMaterialsUpdate(materials: {
    materials: Assignment[]
    courseID: string
    cached?: boolean
    error?: string | null
  }) {
    if (materials.error) {
      console.error(materials.error)
      return
    }
    if (materials.courseID !== this.courseID) return
    if (!this.materials) {
      this.materials = new Map()
    }
    //   console.log('onMaterialsUpdate', materials))
    for (const material of materials.materials) {
      this.materials.set(material.id, mergeMaterial(this.materials.get(material.id), material))
    }
    this.emit('materialUpdate', {
      materials: this.materials,
      cached: !!materials.cached,
    })
  }
  async onOverdueUpdate(overdue: { overdue: string[]; courseID: string; cached?: boolean; error?: string | null }) {
    if (overdue.error) {
      console.error(overdue.error)
      return
    }
    if (overdue.courseID !== this.courseID) return
    this.overdue = new Set(overdue.overdue)
    this.emit('overdueUpdate', {
      overdue: this.overdue,
      cached: !!overdue.cached,
    })
  }
  async onUpcomingEventsUpdate(upcomingEvents: {
    upcoming: UpcomingCourseEvent[]
    courseID: string
    cached?: boolean
    error?: string | null
  }) {
    if (upcomingEvents.error) {
      console.error(upcomingEvents.error)
      return
    }
    if (upcomingEvents.courseID !== this.courseID) return
    if (!this.upcomingEvents) {
      this.upcomingEvents = new Map()
    }
    for (const upcomingEvent of upcomingEvents.upcoming) {
      this.upcomingEvents.set(upcomingEvent.id, upcomingEvent)
    }
    this.emit('upcomingEventsUpdate', {
      upcomingEvents: this.upcomingEvents,
      cached: !!upcomingEvents.cached,
    })
    ;(await localforage).setItem(`course.${this.courseID}.upcomingEvents`, this.upcomingEvents)
  }
  async onCourseStructureUpdate(courseStructure: {
    structure: CourseStructure
    courseID: string
    cached?: boolean
    error?: string | null
  }) {
    if (courseStructure.error) {
      console.error(courseStructure.error)
      return
    }
    if (courseStructure.courseID !== this.courseID) return
    this.courseStructure = courseStructure.structure
    this.emit('courseStructureUpdate', {
      courseStructure: this.courseStructure,
      cached: !!courseStructure.cached,
    })
    if (!courseStructure.cached) {
      console.log('refreshed course structure')
      NotificationsClass.getInstance().addNotif({
        type: 'success',
        title: 'Course structure refreshed',
        message:
          'Course structure has been refreshed, all changes should be up to date. To refesh again, click on the Materials tab.',
      })
    }
  }
  async getMaterials(cachable: boolean = true, important: boolean = false) {
    if (localStorage.getItem('token')) return this.getMaterialsSocket(!cachable)
    return this.getMaterialsREST(cachable, important)
  }
  private async getMaterialsSocket(fresh: boolean) {
    if (fresh) {
      SocketManager.getInstance().socket.emit('course.materials', this.courseID)
      return
    }
    SocketManager.getInstance().socket.emit('cached.course.materials', this.courseID)
  }
  private async getMaterialsREST(cachable: boolean = true, important: boolean = false) {
    this.fetchingMaterials = true
    const response = await fetcher(
      `${API_DOMAIN}/courses/${this.courseID}/materials${!cachable ? `?fresh=true` : ''}`,
      undefined,
      important ? 'important' : 'default'
    )
    const data = await response.json()
    const materials = data.materials as Assignment[]
    this.materials = this.materials ?? new Map()
    materials.map(mat => {
      this.materials!.set(mat.id, mergeMaterial(this.materials!.get(mat.id), mat))
      this.emit('materialUpdate', {
        material: mat,
        cached: cachable,
      })
    })
    this.writeToCache()
    this.fetchingMaterials = false
    return data.materials
  }
  async getMaterial(materialID: string, cachable: boolean = true, noCache: boolean = false) {
    const response = await fetcher(
      `${API_DOMAIN}/courses/${this.courseID}/material/${materialID}${
        !cachable ? `?fresh=true` : noCache ? `?nocache=true` : ''
      }`,
      undefined,
      'important'
    )
    const data = await response.json()
    const material = data.material as Assignment
    this.materials = this.materials ?? new Map()
    this.materials.set(material.id, mergeMaterial(this.materials.get(material.id), material))
    this.emit('materialUpdate', {
      material,
      cached: cachable,
    })
    this.writeToCache()
    return material
  }
  async getOverdueMaterials(imp: boolean = false) {
    if (localStorage.getItem('token')) return this.getOverdueMaterialsSocket(imp)
    return this.getOverdueMaterialsREST(imp)
  }
  private async getOverdueMaterialsSocket(important: boolean = false) {
    SocketManager.getInstance().socket.emit('course.overdue', this.courseID, important)
  }
  private async getOverdueMaterialsREST(imp: boolean = false) {
    //   console.log('[CourseMaterialsDataClass] getOverdueMaterials', imp))
    const response = await fetcher(
      `${API_DOMAIN}/courses/${this.courseID}/materials/overdue`,
      undefined,
      imp ? 'important' : 'default'
    )
    const data = await response.json()
    this.overdue = new Set(data.materials as string[])
    this.emit('overdueUpdate', {
      overdue: this.overdue,
      cached: false,
    })
    this.writeToCache()
    return data.materials as string[]
  }
  async getUpcomingEvents() {
    if (localStorage.getItem('token')) return this.getUpcomingEventsSocket()
    return this.getUpcomingEventsREST()
  }
  private async getUpcomingEventsSocket() {
    SocketManager.getInstance().socket.emit('course.upcoming', this.courseID)
  }
  private async getUpcomingEventsREST() {
    this.fetchingUpcoming = true
    const response = await fetcher(
      `${API_DOMAIN}/courses/${this.courseID}/materials/upcoming?fresh=1`,
      undefined,
      'bulkActive'
    )
    const data = (await response.json()) as { success: boolean; events: UpcomingCourseEvent[] }
    this.upcomingEvents = this.upcomingEvents ?? new Map()
    data.events.map(event => {
      this.upcomingEvents!.set(event.id, event)
    })
    this.emit('upcomingEventsUpdate', {
      upcomingEvents: this.upcomingEvents,
      cached: false,
    })
    this.writeToCache()
    this.fetchingUpcoming = false
    return data.events
  }
  async getCourseStructure(fresh: boolean = false) {
    if (localStorage.getItem('token')) return this.getCourseStructureSocket(fresh)
    return this.getCourseStructureREST(fresh)
  }
  private async getCourseStructureSocket(fresh: boolean) {
    if (fresh) {
      SocketManager.getInstance().socket.emit('course.structure', this.courseID)
      return
    }
    SocketManager.getInstance().socket.emit('cached.course.structure', this.courseID)
  }
  private async getCourseStructureREST(fresh: boolean = false) {
    const response = await fetcher(
      `${API_DOMAIN}/courses/${this.courseID}/materials/structure?${fresh ? 'fresh=true' : ''}`,
      undefined,
      fresh ? 'important' : 'background'
    )
    const data =
      response.ok &&
      ((await response.json()) as {
        success: true
        cached?: boolean
        lastUpdated?: number
        structure: CourseStructure
      })
    if (data) {
      this.courseStructure = data.structure
      this.emit('courseStructureUpdate', {
        courseStructure: data.structure,
        cached: data.cached ?? false,
      })
      this.writeToCache()
      if (fresh) {
        NotificationsClass.getInstance().addNotif({
          type: 'success',
          title: 'Course structure refreshed',
          message:
            'Course structure has been refreshed, all changes should be up to date. To refesh again, click on the Materials tab.',
        })
      }
    }
    return data && data.structure
  }
}
