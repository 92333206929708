import { useState, useEffect } from "react";
import { Course } from "../../types/courseTypes";
import { useCurrentUser } from "../self/useSelfInfo";
import { useArrayedUserCommunityCourses } from "./useArrayedUserCommunityCourses";
import { useUserCourses } from "../course/useUserCourses";

export const useArrayedUserPrimaryCommunityCourses = () => {
  const user = useCurrentUser();
  const courses = useArrayedUserCommunityCourses(user?.primaryCommunity);
  return courses;
}