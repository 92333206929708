import { extend } from 'dayjs'
import EventEmitter from 'events'
import { API_DOMAIN } from '../constants'
import { User } from '../types/userTypes'
import { fetcher } from './Fetcher'
import { SocketManager } from './SocketManager'
export class QuickSearchClass extends EventEmitter {
  private static instance: QuickSearchClass
  private constructor() {
    super()
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new QuickSearchClass()
    }
    return this.instance
  }
  // async listenForSelfUpdates() {
  //   const update = (event: 'selfUpdate', user: User) => {
  //     this.user = user
  //     this.emit('self-update', user)
  //   }
  //   SocketManager.getInstance().addListener('selfUpdate', update.bind(this))
  // }
  setState(open: boolean) {
    if (open) {
      this.emit('open')
    } else {
      this.emit('close')
    }
  }
  toggle() {
    this.emit('toggle')
  }
}
