import { InformationCircleIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { KeybindManager } from '../../../utils/classes/KeybindsClass'
import { QuickSearchClass } from '../../../utils/classes/QuickSearchClass'
import { usePrimaryCommunity } from '../../../utils/hooks/community/usePrimaryCommunity'
import { useCourse } from '../../../utils/hooks/course/useCourse'
import { useUserColors } from '../../../utils/hooks/customization/colors'
import { useQuickSearch } from '../../../utils/hooks/quicksearch/useQuickSearch'
import { useQuickSearchToggleKeybinds } from '../../../utils/hooks/quicksearch/useQuickSearchToggleKeybinds'
import { SearchType } from '../../../utils/types/quicksearch'
import { QuickSearchResult } from './QuickSearchResult'

export const QuickSearchMain = () => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [localFilter, setLocalFilter] = useState(false)
  const [filterAllowed, setFilterAllowed] = useState(true)
  // Create a filter set with all types enabled
  const filters = useRef(new Set<SearchType>([SearchType.Course, SearchType.Material]))
  const searchBox = useRef<HTMLInputElement>(null)
  const results = useQuickSearch(search, open, filters.current, localFilter)
  const router = useRouter()
  const community = usePrimaryCommunity()
  const course = useCourse(router.query.course as string | undefined)
  const color = useUserColors()

  useQuickSearchToggleKeybinds()
  useLayoutEffect(() => {
    const onOpen = () => {
      setOpen(true)
    }
    const onClose = () => {
      setOpen(false)
    }
    const onToggle = () => {
      setOpen(o => !o)
    }

    QuickSearchClass.getInstance().addListener('open', onOpen)
    QuickSearchClass.getInstance().addListener('close', onClose)
    QuickSearchClass.getInstance().addListener('toggle', onToggle)

    return () => {
      QuickSearchClass.getInstance().removeListener('open', onOpen)
      QuickSearchClass.getInstance().removeListener('close', onClose)
      QuickSearchClass.getInstance().removeListener('toggle', onToggle)
    }
  }, [])
  useEffect(() => {
    const resultUp = {
      keys: ['ArrowUp'],
      name: 'QuickSearch Up',
      description: 'Moves selected result from quick search up',
      callback: () => {
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1)
        }
      },
      allowRepeat: true,
    }
    const resultDown = {
      keys: ['ArrowDown'],
      name: 'QuickSearch Down',
      description: 'Moves selected result from quick search down',
      callback: () => {
        if (selectedIndex < (results?.length ?? 0) - 1) {
          setSelectedIndex(selectedIndex + 1)
        }
      },
      allowRepeat: true,
    }
    const resultEnter = {
      keys: ['Enter'],
      name: 'QuickSearch Enter',
      description: 'Selects selected result from quick search',
      callback: () => {
        if (results?.length) {
          const result = results[selectedIndex]
          if (result) {
            result.href && router.push(result.href)
            result.onClick && result.onClick()
            setOpen(false)
          }
        }
      },
    }
    const tabOpen = {
      keys: ['Tab'],
      name: 'QuickSearch Tab',
      description: 'Toggle Quicksearch local filter',
      callback: () => {
        setLocalFilter(l => !l)
      },
    }
    if (!open) return
    KeybindManager.getInstance().addKeybind(resultDown)
    KeybindManager.getInstance().addKeybind(resultUp)
    KeybindManager.getInstance().addKeybind(resultEnter)
    filterAllowed && KeybindManager.getInstance().addKeybind(tabOpen)
    return () => {
      KeybindManager.getInstance().removeKeybind(resultDown)
      KeybindManager.getInstance().removeKeybind(resultUp)
      KeybindManager.getInstance().removeKeybind(resultEnter)
      filterAllowed && KeybindManager.getInstance().removeKeybind(tabOpen)
    }
  }, [results, router, selectedIndex, open, filterAllowed])
  useEffect(() => {
    setSelectedIndex(0)
  }, [results])
  useEffect(() => {
    setFilterAllowed(!!router.query.course)
  }, [router.query])
  useLayoutEffect(() => {
    setSearch('')
    open ? searchBox.current?.focus() : searchBox.current?.blur()
  }, [open])
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full dark:bg-black/80 bg-gray-900/40 z-40  ${
        !open && `opacity-0 pointer-events-none`
      } transition-all duration-200`}
      onClick={() => setOpen(false)}
    >
      <div className={`relative w-full h-full`}>
        <div
          className={`max-w-[90%] w-[65ch] ${
            results?.length ? `max-h-[70%]` : `max-h-[50%]`
          } h-full dark:bg-gray-850 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg overflow-hidden transition-all flex flex-col shadow-md ${
            !open && 'scale-50'
          } duration-75`}
          onClick={e => e.stopPropagation()}
        >
          <div className={`w-full h-20 flex flex-row items-center flex-shrink-0 z-10`}>
            <input
              className={`w-full h-full text-lg px-8 dark:bg-gray-750 bg-gray-50 hover:bg-white focus:bg-white drop-shadow-sm hover:shadow-lg focus:shadow-lg hover:outline hover:outline-gray-100 focus:outline focus:outline-gray-100 dark:hover:outline-gray-800 dark:focus:outline-gray-800 dark:text-gray-200 transition-all`}
              placeholder={`What are you looking for?`}
              value={search}
              onChange={e => setSearch(e.target.value)}
              ref={searchBox}
            />
          </div>
          {filterAllowed && (
            <div className={`dark:bg-gray-800 px-8 py-2 flex flex-col gap-2`}>
              <span className={`font-bold font-montserrat text-xs dark:text-gray-100/20`}>Search only in</span>
              <div className={`flex flex-row gap-2`}>
                <div
                  className={`dark:border-gray-100/10 border p-1 px-2 text-sm dark:text-gray-100 rounded-full flex flex-row gap-2 items-center cursor-pointer transition-all relative overflow-hidden dark:hover:bg-gray-100/5`}
                  onClick={() => setLocalFilter(l => !l)}
                >
                  <img src={course?.icon} className={`w-4 h-4 bg-gray-900 rounded-full z-10`} />
                  <span className={`z-10`}>{course?.name}</span>
                  <div
                    className={`p-0.5 ${
                      localFilter ? `dark:border-gray-100/50` : `dark:border-gray-100/10`
                    } transition-all border px-1 text-xs rounded-lg z-10 md:hidden`}
                  >
                    Tab
                  </div>
                  <div
                    className={`absolute top-0 left-0 w-full h-full ${
                      localFilter && `dark:hover: bg-${color} dark:brightness-90`
                    } transition-all z-0`}
                  ></div>
                </div>
              </div>
            </div>
          )}
          <div className={`w-full flex-grow relative bg-gray-100 dark:bg-gray-850`}>
            <div className={`absolute top-0 left-0 overflow-auto w-full h-full flex flex-col items-start gap-1 p-4`}>
              {!results && (
                <div className={`flex flex-col items-center justify-center w-full h-full dark:text-gray-400 gap-6`}>
                  <span>Type to search</span>
                </div>
              )}
              {results?.map((result, index) => (
                <QuickSearchResult
                  result={result}
                  key={result.id}
                  selected={index === selectedIndex}
                  onSelect={() => setSelectedIndex(index)}
                />
              ))}
            </div>
          </div>
          <div className={`flex flex-row gap-2 p-1.5 px-8 dark:bg-gray-900/50 dark:text-gray-100/40 items-center`}>
            <span
              className={`text-sm font-medium font-wsans text-${color} text-opacity-50 brightness-125`}
            >
              Tip:
            </span>
            <span className={`text-sm`}>
              Starting a search with{' '}
              <span className={`font-bold text-${color}`}>~</span> will search
              through paths (eg. Lectures/Day 23).
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
