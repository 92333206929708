import { useLayoutEffect, useState } from 'react'
import { usePrimaryCommunity } from '../community/usePrimaryCommunity'
import { useCurrentUser } from '../self/useSelfInfo'

export const useUserColors = () => {
  const [color, setColor] = useState('')
  const user = useCurrentUser()
  const community = usePrimaryCommunity()
  useLayoutEffect(() => {
    if (user?.theme === 2) return setColor(`teal-500`)
    if (community?.communityObject?.colors.primary) {
      return setColor(community?.communityObject?.colors.primary!)
    }
    return setColor('')
  }, [user, community])
  return color
}
