import { useEffect, useLayoutEffect, useState } from 'react'
import { UserCommunities } from '../../classes/UserCommunities'
import { UserCourses } from '../../classes/UserCourses'
import { getCurrentState } from '../../TetLib'
import { Course, UserCoursesType } from '../../types/courseTypes'
import useForceUpdate from '../../useForceUpdate'
import { useCurrentUser } from '../self/useSelfInfo'

export const useUserCourses = () => {
  const user = useCurrentUser()
  const [communityCourses, setCommunityCourses] = useState(null as null | UserCoursesType)
  useLayoutEffect(() => {
    const updater = () => {
      setCommunityCourses(new Map(UserCourses.getInstance().courses))
    //   console.log('useUserCourses:', user, UserCourses.getInstance()).courses)
      // forceUpdate()
    }
    UserCourses.getInstance().on('coursesUpdate', updater)
    if (!UserCourses.getInstance().fetching && !UserCourses.getInstance().courses.size) {
      UserCourses.getInstance().fetch()
    }
    updater()
    return () => {
      UserCourses.getInstance().removeListener('coursesUpdate', updater)
    }
  }, [])
  // useEffect(()=>{
  // //   console.log('user courses changed',communityCourses,UserCourses.getInstance()).courses)
  // },[communityCourses])
  // if (!user) return null
  // console.log('userCourses', communityCourses)
  return communityCourses
}
