import React from 'react'
import { getPersistentStore } from './PersistentStore'

export type CachedFile = {
  name: string
  content: Blob
  modified: string
}
export class FilesCacher {
  cache: Map<string, CachedFile>
  ready: boolean
  constructor() {
    this.cache = new Map()
    this.initizeCache()
    this.ready = false
  }
  async initizeCache(): Promise<void> {
    const cache = await (await getPersistentStore).getItem('filesCache')
    if (cache) {
      this.cache = cache as Map<string, CachedFile>
    }
    this.ready = true
  }
  get(key: string): CachedFile | undefined {
    return this.cache.get(key)
  }
  async promiseGet(key: string): Promise<CachedFile> {
    return this.waitForFile(key)
  }
  async set(key: string, cachedFile: CachedFile) {
    this.cache.set(key, cachedFile)
    await (await getPersistentStore).setItem('filesCache', this.cache)
  }
  delete(key: string): void {
    this.cache.delete(key)
  }
  async waitForFile(key: string): Promise<CachedFile> {
    if (this.cache.has(key)) {
      return this.cache.get(key)!
    }
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (this.cache.has(key)) {
          clearInterval(interval)
          resolve(this.cache.get(key)!)
        }
      }, 100)
    })
  }
}
export const FilesCache = new FilesCacher()
export const FilesCacheContext = React.createContext<FilesCacher>(FilesCache)
export const FilesCacheProvider = FilesCacheContext.Provider
