import { extend } from 'dayjs'
import EventEmitter from 'events'
import { API_DOMAIN } from '../constants'
import { User } from '../types/userTypes'
import { fetcher } from './Fetcher'
import { SocketManager } from './SocketManager'
export class SelfUserClass extends EventEmitter {
  user: User | null = null
  cached: boolean
  private static instance: SelfUserClass
  private constructor() {
    super()
    this.user = globalThis?.localStorage?.getItem('cachedSelf')
      ? JSON.parse(globalThis?.localStorage?.getItem('cachedSelf') || '')
      : null
    this.user && this.emit('self-update', this.user)
    this.cached = !!this.user
    this.setMaxListeners(100)
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new SelfUserClass()
      this.instance.fetch()
    }
    return this.instance
  }
  async listenForSelfUpdates() {
    const update = (data: { user: User }) => {
    //   console.log('self update', data))
      const { user } = data
      this.user = user
      this.emit('self-update', user)
    }
    SocketManager.getInstance().on('selfResponse', update.bind(this))
  }
  async fetch() {
    const self = SelfUserClass.getInstance()
    if (!globalThis?.localStorage?.getItem('token')) {
      self.user = null
      self.emit('self-update', null)
      self.cached = false
      return
    }
    const socketeer = SocketManager.getInstance()
    if (!socketeer.connecting && !socketeer.ready) {
    //   console.log('connecting socket'))
      socketeer.connect()
    }
    while (!socketeer.ready) {
    //   console.log('waiting for socket'))
      await new Promise(resolve => setTimeout(resolve, 100))
    }
  //   console.log('socket ready'))
    socketeer.socket.emit('user.@self', '')
  //   console.log('emitted'))
    const user = await new Promise<User>(resolve => {
      socketeer.once('selfResponse', (data: { user: User }) => {
      //   console.log('responded', data))
        const { user } = data
        resolve(user)
      })
    })
  //   console.log('got user', user))
    if (user) {
      self.user = user
      self.emit('self-update', user)
      globalThis?.localStorage?.setItem('cachedSelf', JSON.stringify(user))
      self.cached = false
    } else {
      self.user = null
      self.emit('self-update', null)
      self.cached = false
    }

    return this.user
  }
}
