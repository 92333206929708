import { useState, useEffect } from "react";
import { Course } from "../../types/courseTypes";
import { useUserCourses } from "../course/useUserCourses";

export const useArrayedUserCommunityCourses = (communityID?: string) => {
  const courses = useUserCourses();
  const [arrayedCourses, setArrayedCourses] = useState(null as null | Course[]);
  useEffect(() => {
    if (courses) {
      const coursesArr = [] as Course[];
      const communityCourses = courses.get(communityID || '');
      if (communityCourses) {
        Array.from(communityCourses.values()).forEach((course) =>
          course && coursesArr.push(course));
      }

      setArrayedCourses(coursesArr);

    }
  }, [communityID, courses])
  return arrayedCourses;
}