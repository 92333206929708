import { useEffect } from "react"
import { usePrimaryCommunity } from "../../utils/hooks/community/usePrimaryCommunity"
import { useUserColors } from "../../utils/hooks/customization/colors"
import { configureColor } from "../../utils/nprogress"

export const ProgressBar = () => {
  const color = useUserColors()
  useEffect(()=>{
    if (!color) return configureColor('teal-500')
  //   console.log(primaryCommunity))
    if (color){
      configureColor(color)
    }
  },[color])
  return null
}
// export default ProgressBar