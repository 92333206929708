import { Community } from '../../types/communityTypes'
import { Assignment, Course } from '../../types/courseTypes'
import { SearchMaterial, SearchType } from '../../types/quicksearch'

const CourseSearchParser = (course: Course, community?: Community) =>
  ({
    id: course.id,
    name: course.name,
    parent: community?.name,
    type: SearchType.Course,
    href: `/app/courses/${course.id}`,
    icon: course.icon,
    path: `${community?.name}/${course.name}`,
  } as SearchMaterial)

const AssignmentSearchParser = (assignment: Assignment, course: Course, community?: Community) => {
  if (!assignment.title) {
    return null
  }

  return {
    id: `${course.id}-${assignment.id}`,
    name: assignment.title,
    parent: `${community?.name}/${course.name}`,
    type: SearchType.Material,
    href: `/app/courses/${course.id}/materials/${assignment.id}`,
    icon: course.icon,
    path: `~/${
      assignment.path?.filter(p => p.id !== '__root').length
        ? assignment.path
            .filter(p => p.id !== '__root')
            .map(p => p.title)
            .join('/')
            .concat('/')
        : ``
    }${assignment.title}`,
  } as SearchMaterial
}

export const SearchParsers = {
  CourseSearchParser,
  AssignmentSearchParser,
}
