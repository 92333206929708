import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { parse } from 'snapsvg'
import { FuzzyMatchAsync } from '../../APIs/SearchAPIs/fuzzylib'
import { SearchParsers } from '../../APIs/SearchAPIs/SearchParsers'
import { NotificationsClass } from '../../classes/NotificationsClass'
import { SearchMaterial, SearchType } from '../../types/quicksearch'
import { useAllMaterials } from '../community/useAllMaterials'
import { useArrayedUserPrimaryCommunityCourses } from '../community/useArrayedUserPrimaryCommunityCourses'
import { usePrimaryCommunity } from '../community/usePrimaryCommunity'

export const useQuickSearch = (term: string, open: boolean, filterTypes: Set<SearchType>, locationFilter: boolean) => {
  const [results, setResults] = useState(null as SearchMaterial[] | null)

  const courses = useArrayedUserPrimaryCommunityCourses()
  const materials = useAllMaterials()
  const community = usePrimaryCommunity()
  const router = useRouter()
  useEffect(() => {
    if (!term) return setResults(null)
    const agg = [] as (SearchMaterial | null)[]
    if (!filterTypes.size) {
      filterTypes.add(SearchType.Course)
      filterTypes.add(SearchType.Material)
      filterTypes.add(SearchType.Page)
      filterTypes.add(SearchType.User)
    }
    if (filterTypes.has(SearchType.Course) && courses && !locationFilter)
      agg.push(...courses.map(x => SearchParsers.CourseSearchParser(x, community?.communityObject)))
    if (filterTypes.has(SearchType.Material) && materials)
      agg.push(
        ...materials
          .filter(mat => (locationFilter ? mat[0].id === router.query.course : true))
          .map(x => SearchParsers.AssignmentSearchParser(x[1], x[0], community?.communityObject))
      )

    const aggregate = agg.filter(x => x) as SearchMaterial[]
    const aggregateMap = new Map<string, Set<SearchMaterial>>()
    aggregate
      .filter(x => x)
      .forEach(material => {
        const key = material.name
        if (!aggregateMap.has(key)) {
          aggregateMap.set(key, new Set())
        }
        aggregateMap.get(key)!.add(material)
      })
    let asyncMatcher: FuzzyMatchAsync
    let asyncPathMatcher: FuzzyMatchAsync
    const asyncMatchPromise = new Promise<SearchMaterial[]>(resolve => {
      asyncMatcher = new FuzzyMatchAsync(
        term,
        aggregate.map(x => x.name),
        results => {
          const sortedResults = results.sort((b, a) => a[0] - b[0])
          //   console.log(sortedResults.map(x=>x[0])),aggregate,courses)
          const parsedResults = sortedResults
            .slice(0, 100)
            .flatMap(x =>
              Array.from(aggregateMap.get(x[1])?.values() || []).map(y => ({ ...y, flaggedName: x[2], score: x[0] }))
            )
            .filter(x => x) as SearchMaterial[]
          // const dedupe = new Set<string>()
          // const dedupedResults = parsedResults.filter(x => {
          //   const key = JSON.stringify(x)
          //   if (dedupe.has(key)) return false
          //   dedupe.add(key)
          //   return true
          // })
          console.log({ sortedResults, parsedResults }, 'nameMatch')
          resolve(parsedResults)
        }
      )
      asyncMatcher.start()
    }) as Promise<SearchMaterial[]>
    const asyncPathMatchPromise = new Promise<SearchMaterial[]>(resolve => {
      const reverseMap = new Map<string, string>()
      aggregate.map(x => x.path && reverseMap.set(x.path, x.name))

      asyncPathMatcher = new FuzzyMatchAsync(
        term,
        aggregate.map(x => x.path!),
        results => {
          const sortedResults = results
            .map((a, i) => [a[0], a[1], a[2]] as [number, string, string])
            .sort((b, a) => a[0] - b[0])
          //   console.log(sortedResults.map(x=>x[0])),aggregate,courses)
          const parsedResults = sortedResults
            .slice(0, 100)
            .flatMap(x =>
              Array.from(aggregateMap.get(reverseMap.get(x[1]) ?? '')?.values() || []).map(y => ({
                ...y,
                flaggedPath: x[2],
                score: x[0],
              }))
            )
            .filter(x => x) as SearchMaterial[]
          // const dedupedResults = parsedResults.filter(x => {
          //   const key = JSON.stringify(x)
          //   if (dedupe.has(key)) return false
          //   dedupe.add(key)
          //   return true
          // })
          console.log({ sortedResults, parsedResults }, 'pathMatch')
          resolve(parsedResults)
        },
        {
          max_leading_letter_penalty: -5,
          leading_letter_penalty: -1,
          first_letter_bonus: 50,
        }
      )
      asyncPathMatcher.start()
    }) as Promise<SearchMaterial[]>
    let cancel = false
    Promise.all([asyncMatchPromise, asyncPathMatchPromise]).then(([a, b]) => {
      const dedupe = new Set<string>()
      // console.log(b);
      const dedupeArr = [...a, ...b]
        .sort((a, b) => b.score! - a.score!)
        .filter(x => {
          const check = Object.assign({}, x)
          delete check.score
          delete check.flaggedName
          delete check.flaggedPath
          const key = JSON.stringify(check)
          if (dedupe.has(key)) return false
          dedupe.add(key)
          return true
        })
      // ;[...a, ...b].map(x => {
      //   const key = JSON.stringify({
      //     ...x,
      //     flaggedName: undefined,
      //     flaggedPath: undefined,
      //   } as SearchMaterial)
      //   if (dedupe.has(key)) {
      //     const score = dedupe.get(key)!
      //     if (score[0] < x.score!) {
      //       dedupeArr[score[1]] = x
      //       dedupe.set(key, [x.score!, score[1]])
      //     }
      //     return false
      //   }
      //   dedupe.set(key, [x.score!, dedupeArr.length])
      //   dedupeArr.push(x)
      //   return true
      // })
      // conxs
      if (term.match(/^kimiko/i)) {
        dedupeArr.unshift({
          name: 'Kimiko Theme',
          icon: 'https://cdn.discordapp.com/attachments/957350482639212545/969444206395420682/IMG_0634.png',
          id: 'KimikoTheme',
          type: SearchType.Theme,
          onClick: () => {
            NotificationsClass.getInstance().addNotif({
              title: 'New Theme Found',
              message: 'Kimiko Theme has been added to your themes!',
              type: 'success',
              image: 'https://cdn.discordapp.com/attachments/957350482639212545/969444206395420682/IMG_0634.png',
            })
            router.push('/app/settings/customize?funny=1')
            localStorage.setItem(`kimikoThemeFound`, 'true')
          },
        })
      }
      !cancel && setResults(dedupeArr.slice(0, 50))
    })
    return () => {
      //   console.log('stopping'))
      asyncMatcher.cancel()
      asyncPathMatcher.cancel()
      cancel = true
    }
  }, [term, open, filterTypes, courses, community, locationFilter])
  return results
}
