import { useState, useEffect } from 'react'
import { Assignment, Course, CourseFolder, CourseStructure } from '../../types/courseTypes'
import { useCurrentUser } from '../self/useSelfInfo'
import { useArrayedUserCommunityCourses } from './useArrayedUserCommunityCourses'
import { useUserCourses } from '../course/useUserCourses'
import { CourseMaterialsDataClass } from '../../classes/CourseDataClasses/CourseAssignmentDataClass'

// function getMats(data: CourseMaterialsDataClass, course: Course) {
//   const mats = Array.from(data.materials?.values() || [])
//   return mats.map(mat => [mat, course]) as [Assignment, Course][]
// }

export const useAllMaterials = () => {
  const user = useCurrentUser()
  const [materials, setMaterials] = useState(null as [Course, Assignment][] | null)
  const courses = useArrayedUserCommunityCourses(user?.primaryCommunity)
  useEffect(() => {
    if (!courses || !courses.length) return
    let cancel = [] as (() => void)[]
    const instances = courses.map(course => CourseMaterialsDataClass.getInstance(course.id, false))
    const structPathMap = new Map<string, Map<string, CourseFolder[]>>()
    instances.forEach((instance, ind) => {
      let structPaths = new Map<string, CourseFolder[]>()
      const parseBacktree = (struct?: CourseStructure | null) => {
        if (!struct) return
        // for each folder, find the path to the root
        let structMap = new Map<string, CourseFolder[]>()
        const folders = Array.from(Object.values(struct))
        folders.forEach(folder => {
          let path = [folder]
          let parent = folder.parent
          // let tries = 5
          let trackpath = Math.random()
          while (parent && struct[parent] && parent !== '__root') {
            // console.log(trackpath, parent, struct[parent], struct)
            // console.log(parent)
            path.push(struct[parent])
            parent = struct[parent].parent
          }
          structMap.set(`${folder.id}`, path)
        })
        structPathMap.set(instance.courseID, structMap)
        // console.log({ structPaths }, 'structPaths')
      }
      const onUpdate = (event: any) => {
        const materials = instances.flatMap((instance, ind) => {
          // console.log(instance.materials, 'mats')
          return (
            Array.from(instance.materials?.values() || [])
              .map(a => ({
                ...a,
                path:
                  [
                    ...(structPathMap
                      ?.get(courses[ind]?.id)
                      ?.get(`${a.parent}`)
                      ?.filter(f => f.id !== `__root`) || [])!,
                  ].reverse() || [],
                // locator:
                //   courses[ind]?.id === instance.courseID
                //     ? `${a.parent}, ${structPaths.get(`${a.parent}`)} ${JSON.stringify(
                //         Object.fromEntries(structPaths.entries())
                //       )}`
                //     : '',
              }))
              .map(x => [courses[ind], x] as [Course, Assignment]) ?? []
          )
        })
        setMaterials(materials)
        // console.log('onupdate', materials, structPaths)
      }
      const onStructureUpdate = (event: any) => {
        parseBacktree(instance.courseStructure)
        onUpdate(event)
      }
      onStructureUpdate(null)
      instance.on('materialUpdate', onUpdate)
      instance.on('courseStructureUpdate', onStructureUpdate)
      cancel.push(() => instance.off('materialUpdate', onUpdate))
      cancel.push(() => instance.off('courseStructureUpdate', onStructureUpdate))
    })
    // setMaterials(
    //   instances.flatMap(
    //     (instance, ind) =>
    //       Array.from(instance.materials?.values() || []).map(x => [courses[ind], x] as [Course, Assignment]) ?? []
    //   )
    // )
    return () => {
      cancel.forEach(c => c())
    }
  }, [courses])
  return materials
}
