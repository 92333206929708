import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { QuickSearchClass } from '../../../utils/classes/QuickSearchClass'
import { usePrimaryCommunity } from '../../../utils/hooks/community/usePrimaryCommunity'
import { useUserColors } from '../../../utils/hooks/customization/colors'
import { SearchMaterial } from '../../../utils/types/quicksearch'

const parseSearchFlag = (searchFlag: string = '', color: string = ''): JSX.Element[] => {
  const terms = searchFlag.split('<sp>')
  // format only every second term
  const formattedTerms = terms.map((term, index) => {
    if (index % 2 === 0) {
      return <span key={index}>{term}</span>
    }
    return (
      <span key={index} className={`font-semibold text-${color} group-hover:text-opacity-70`}>
        {term}
      </span>
    )
  })
  return formattedTerms
  // .concat(<div>
  //   {JSON.stringify(terms)}
  // </div>,<div>
  //   {JSON.stringify({searchFlag})}
  // </div>)
}

export const QuickSearchResult = (props: { result: SearchMaterial; selected: boolean; onSelect: () => void }) => {
  const { result, selected, onSelect } = props
  const community = usePrimaryCommunity()
  const router = useRouter()
  const element = useRef<HTMLDivElement>(null)
  const color = useUserColors()

  useEffect(() => {
    if (selected) {
      element.current?.scrollIntoView({ behavior: 'auto', block: 'nearest' })
    }
  }, [selected, element])
  return (
    <div
      className={`w-full h-auto flex flex-col gap-2 p-4 ${
        selected && `dark:bg-gray-700 bg-white shadow-md outline `
      } dark:outline-gray-750 outline-gray-100 rounded-lg transition-all cursor-pointer group`}
      onClick={() => {
        QuickSearchClass.getInstance().toggle()
        result.href && router.push(result.href)
        result.onClick && result.onClick()
      }}
      onMouseEnter={onSelect}
      ref={element}
    >
      <div className={`flex flex-row items-center gap-2`}>
        {typeof result.icon === 'string' ? (
          <img className={`w-12 h-12 object-cover rounded-lg`} src={result.icon} alt={`${result.type} icon`} />
        ) : (
          <result.icon className={`w-12 h-12`} />
        )}
        {/* {JSON.stringify(result.icon)} */}
        <div className={`flex flex-col gap-0.5 justify-center`}>
          <span
            className={`dark:text-gray-300 text-lg group-hover:text-${color} group-hover:text-opacity-70 transition-all`}
          >
            {parseSearchFlag(result.flaggedName ?? result.name, color)}
          </span>
          {result.parent && (
            <span className={`text-sm font-light dark:text-gray-400`}>
              from <span className={`font-medium`}>{result.parent}</span>
            </span>
          )}
          {result.path && (
            <span className={`text-sm font-light dark:text-gray-400`}>
              {result.flaggedPath ? parseSearchFlag(result.flaggedPath) : result.path}
            </span>
          )}
          {/* {JSON.stringify(result)} */}
        </div>
      </div>
    </div>
  )
}
