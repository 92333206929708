import { useLayoutEffect } from 'react'
import { UserCommunities } from '../../classes/UserCommunities'
import useForceUpdate from '../../useForceUpdate'
import { useCurrentUser } from '../self/useSelfInfo'
import { useUserCommunities } from './useUserCommunities'

export const usePrimaryCommunity = () => {
  const user = useCurrentUser()
  const communities = useUserCommunities()
  const forceUpdate = useForceUpdate()
  useLayoutEffect(() => {
    UserCommunities.getInstance().on('communitiesUpdate', forceUpdate)
    return () => {
      UserCommunities.getInstance().removeListener('communitiesUpdate', forceUpdate)
    }
  }, [user, communities])
  if (!user || !communities) return null
  const primary = communities.get(user.primaryCommunity!)
  return primary
}
