import { Transition } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { SidebarModuleType } from '../../../utils/types/moduleTypes'
import { ModuleButtonPopper } from './ModuleButton/ModuleButtonPopper'
import { ModuleListItem } from './ModuleButton/ModuleListItem'

export const ModuleItem = (props: { open: boolean; module: SidebarModuleType; index: number }) => {
  const { open, module, index } = props
  const router = useRouter()
  if (!module.url) return null
  return (
    <div
      className={`relative ${open ? `w-full` : `w-full md:w-10`} h-10 transition-all`}
      onClick={e => {
        router.push(module.url)
        e.stopPropagation()
      }}
    >
      <div
        className={`absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transition-all ${
          open ? `opacity-0 pointer-events-none` : `opacity-100`
        }`}
      >
        <ModuleButtonPopper module={module} />
      </div>
      <div
        className={`absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transition-all w-full ${
          open ? `opacity-100` : `opacity-0 pointer-events-none`
        }`}
      >
        <ModuleListItem module={module} index={index} />
      </div>
    </div>
  )
}
