import EventEmitter from 'events'
import { API_DOMAIN } from '../constants'
import { getPersistentStore } from '../PersistentStore'
import { DisadusCommunityJoin } from '../types/communityTypes'
import { Course, UserCoursesType } from '../types/courseTypes'
import { AsyncFetchCacher } from './AsyncFetchCacher'
import { fetcher } from './Fetcher'
import { UserCommunities } from './UserCommunities'

export class UserCourses extends EventEmitter {
  communities: Map<string, DisadusCommunityJoin>
  courses: UserCoursesType = new Map()
  courseMap: Map<string, Course> = new Map() // all courses regardless of enrollment
  cached: boolean
  fetching: boolean = false
  private static instance: UserCourses
  private constructor() {
    super()
    this.communities = UserCommunities.getInstance().communities
    this.cached = true
    this.loadCache()
    this.fetch()
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new UserCourses()
    }
    return this.instance
  }
  async linkSelf() {
    const communities = UserCommunities.getInstance()
    const update = () => {
      this.fetch()
    }
    communities.addListener('communitiesUpdate', update.bind(this))
  }
  async loadCache() {
    const courses = await (await getPersistentStore).getItem('cachedCourses')
    const courseMap = await (await getPersistentStore).getItem('cachedCourseMap')
    if (courseMap) {
      this.courseMap = courseMap as Map<string, Course>
      this.courseMap.forEach(course => {
        this.emit('courseUpdate', course)
      })
    }
    if (courses) {
      this.courses = courses as UserCoursesType
    }
    this.emit('coursesUpdate', this.courses)
  }

  async fetch() {
    if (!globalThis.localStorage?.getItem('token')) return
    this.fetching = true
    const courses = (await fetcher(`${API_DOMAIN}/user/@me/courses`).then(x => x.json())) as Course[]
    this.fetching = false
    // console.log(courses)
    if (courses) {
      const courseMap = new Map()
      const allCourses = new Map()
      courses.forEach(course => {
        if (!courseMap.has(course.communityID)) {
          courseMap.set(course.communityID, new Map())
        }
        courseMap.get(course.communityID)!.set(course.id, course)
        allCourses.set(course.id, course)
        this.emit('courseUpdate', course)
        this.courseMap.set(course.id, course)
      })
      courseMap.set('_all', allCourses)
      this.courses = courseMap
      this.cached = false
      this.emit('coursesUpdate', this.courses)
      ;(await getPersistentStore).setItem('cachedCourses', this.courses)
      ;(await getPersistentStore).setItem('cachedCourseMap', this.courseMap)
      this.cacheAll()
      return this.courses
    }
  }
  async cacheAll() {
    const courseList = Array.from(this.courses.values()).flatMap(x => Array.from(x.values()))
    console.log(
      courseList.map(x => x.id),
      'caching'
    )
    courseList.map(async course => AsyncFetchCacher.getInstance().queueFetch(course.icon, undefined, false))
  }
  async getCourse(courseID: string, fresh: boolean = false) {
    if (!fresh && this.courses.get('_all')?.has(courseID)) {
      return this.courses.get('_all')?.get(courseID)
    }
    const course = (await fetcher(`${API_DOMAIN}/courses/${courseID}`).then(x => x.json())) as Course
    if (course) {
      this.courseMap.set(course.id, course)
      this.emit('courseUpdate', course)
      ;(await getPersistentStore).setItem('cachedCourseMap', this.courseMap)
      return course
    }
  }
}
