import { BookOpenIcon, ChatIcon, CogIcon, HomeIcon, SupportIcon, UserGroupIcon } from '@heroicons/react/outline'
import { SidebarModuleType } from '../types/moduleTypes'

export const sidebarDictionary = {
  home: {
    id: '@core.home',
    name: 'Home',
    description: '',
    icon: <HomeIcon className="w-full h-full" />,
    url: '/app',
    iframe: false,
    type: '@core',
  },
  community: {
    id: '@core.community',
    name: 'Community',
    description: '',
    icon: <UserGroupIcon className="w-full h-full " />,
    url: '/app/communities/@self',
    iframe: false,
    type: '@core',
  },
  courses: {
    id: '@core.courses',
    name: 'Courses',
    description: '',
    icon: <BookOpenIcon className="w-full h-full " />,
    url: '/app/courses',
    iframe: false,
    type: '@core',
  },
  chat: {
    id: '@core.chat',
    name: 'Chat',
    description: '',
    icon: <ChatIcon className="w-full h-full " />,
    url: '/chat',
    iframe: false,
    type: '@core',
  },
  settings: {
    id: '@core.settings',
    name: 'Settings',
    description: '',
    icon: <CogIcon className="w-full h-full " />,
    url: '/app/settings',
    iframe: false,
    type: '@core',
  },
  help: {
    id: '@core.help',
    name: 'Help',
    description: '',
    icon: <SupportIcon className="w-full h-full " />,
    url: '/app/help',
    iframe: false,
    type: '@core',
  },
} as { [key: string]: SidebarModuleType }
