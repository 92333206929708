import { useEffect } from "react"
import { KeybindManager } from "../../classes/KeybindsClass";

export const useKeybindFufiller = () => {
  useEffect(() => {
    const ActiveKeys = new Set<string>();
    const keyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Meta' && event.key !== 'Control' && event.key !== 'cmd') {
        ActiveKeys.add(event.key)
      }
      // console.log(ActiveKeys)

      const keyDowns = Array.from(ActiveKeys.values())
      if (event.metaKey) {
        keyDowns.push('Meta')
      }
      if (event.ctrlKey) {
        keyDowns.push('Control')
      }
      const keybinds = KeybindManager.getInstance().getKeybinds(keyDowns as string[])
      if (keybinds.length > 0) {
        event.preventDefault()
        
        keybinds.forEach(keybind => {
          if (event.repeat && !keybind.allowRepeat) return
          keybind.callback()
        })
        ActiveKeys.clear()
      }
    }
    const keyUp = (event: KeyboardEvent) => {
      ActiveKeys.delete(event.key)
    }
    window.addEventListener('keydown', keyDown)
    window.addEventListener('keyup', keyUp)
    return () => {
      window.removeEventListener('keydown', keyDown)
      window.removeEventListener('keyup', keyUp)
    }
  }, [])

}