import { KeybindType } from "../types/keybindTypes"

export class KeybindManager {
  keybindMap: KeybindType[]
  private static instance: KeybindManager
  private constructor() {
    this.keybindMap = []
  }
  static getInstance() {
    if (!KeybindManager.instance) {
      KeybindManager.instance = new KeybindManager()
    }
    return KeybindManager.instance
  }
  addKeybind(keybind: KeybindType) {
    this.keybindMap.push(keybind)
  }
  removeKeybind(keybind: KeybindType) {
    const index = this.keybindMap.findIndex(kb => kb === keybind)
    if (index !== -1) {
      this.keybindMap.splice(index, 1)
    }
  }
  getKeybinds(keys: string[]) {
    const keybinds = this.keybindMap.filter(kb => kb.keys.every(key => keys.includes(key)))
    return keybinds
  }
}