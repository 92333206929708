import { isMacOs } from 'react-device-detect'

export const SidebarKeyview = (props: { keyName: string }) => (
  <div
    className={`p-1 dark:bg-gray-800 dark:border-gray-600 border-gray-250 group-hover:border-gray-400 border flex flex-row items-center rounded-lg text-xs w-12 justify-center transition-all`}
  >
    <span>{isMacOs ? '⌘' : <>Ctrl&nbsp;</>}</span>
    <span>{props.keyName}</span>
  </div>
)
