import { Dispatch, SetStateAction } from 'react'

/**
 * Returns a tailwind color class given a color string (red,orange,yellow,etc).
 */
export const pastelifyColor = (color: string): string => {
  switch (color) {
    case 'red':
      return '-red-300'
    case 'orange':
      return '-yellow-400'
    case 'yellow':
      return '-yellow-300'
    case 'green':
      return '-green-300'
    case 'blue':
      return '-blue-300'
    case 'purple':
      return '-purple-300'
    case 'pink':
      return '-pink-300'
    case 'gray':
      return '-gray-400'
    case 'black':
      return '-gray-500'
  }
  return '-gray-300'
}
export const inBrowser = (): boolean => {
  return typeof window !== 'undefined'
}
export function getCurrentState<S>(setterFunction: Dispatch<SetStateAction<S>>): S {
  let current = undefined as S | undefined
  setterFunction(curr => {
    current = curr
    return curr
  })
  return current!
}
export const letterGrade = (grade: number) => {
  if (grade === -1) return '-'
  grade *= 100
  if (grade >= 97) return 'A+'
  if (grade >= 93) return 'A'
  if (grade >= 90) return 'A-'
  if (grade >= 87) return 'B+'
  if (grade >= 83) return 'B'
  if (grade >= 80) return 'B-'
  if (grade >= 77) return 'C+'
  if (grade >= 73) return 'C'
  if (grade >= 70) return 'C-'
  if (grade >= 67) return 'D+'
  if (grade >= 63) return 'D'
  if (grade >= 60) return 'D-'
  return 'F'
}
