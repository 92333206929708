import EventEmitter from 'events'
import localforage from 'localforage'
import { API_DOMAIN } from '../constants'
import { sidebarDictionary } from '../data/sidebarDictionary'
import { getPersistentStore } from '../PersistentStore'
import { SidebarModuleType, SidebarPreferenceResponse } from '../types/moduleTypes'
import { User } from '../types/userTypes'
import { fetcher } from './Fetcher'
import { SelfUserClass } from './SelfUserClass'

/*

Fetch user module preferences and 

{"_id":{"$oid":"624524a3388c8ea1404ed148"},"user":"TET00","community":"gunnSandbox","sidebar":["@core.home","@core.community","@core.courses, "plugin.calculator"]}

*/

export class ModuleClass extends EventEmitter {
  user: User | null = null
  moduleMap: Map<string, SidebarModuleType> = new Map()
  cached: boolean
  static instance: ModuleClass
  static getInstance() {
    if (!this.instance) {
      this.instance = new ModuleClass()
    }
    return this.instance
  }
  private constructor() {
    super()
    this.cached = true
  }
  async loadCache() {
    const moduleMap = await (await getPersistentStore).getItem('cachedModules')
    if (moduleMap) {
      this.moduleMap = moduleMap as Map<string, SidebarModuleType>
    }
  }
  async resolveModule(resolver: string) {
    // console.log('resolveModule', resolver)
    const directory = resolver.split('.')
    // console.log(
    //   'directory',
    //   directory,
    //   directory[0] === '@core',
    //   directory[1],
    //   sidebarDictionary,
    //   sidebarDictionary[directory[1]]
    // )
    if (directory[0] === '@core') {
      return sidebarDictionary[directory[1]]
    }
  }
}
