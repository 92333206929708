import { useEffect } from "react"
import { KeybindManager as Keybinder } from "../../classes/KeybindsClass"
import { QuickSearchClass } from "../../classes/QuickSearchClass"

export const useQuickSearchToggleKeybinds = () => {
  useEffect(() => {
    const KeybindManager = Keybinder.getInstance()
    const keybind = {
      keys: [`Meta`, `k`],
      callback: QuickSearchClass.getInstance().toggle.bind(QuickSearchClass.getInstance()),
      name: 'QuickSearch',
      description: `Quickly searches through everything. Uses bang system to filter results`,
    }
    const windowsKeybind = {
      ...keybind,
      keys: [`Control`, `k`],
    }
    const EscapeKeybind = {
      keys: [`Escape`],
      callback: () => QuickSearchClass.getInstance().setState(false),
    }

    KeybindManager.addKeybind(keybind)
    KeybindManager.addKeybind(windowsKeybind)
    KeybindManager.addKeybind(EscapeKeybind)
    return () => {
      KeybindManager.removeKeybind(keybind)
      KeybindManager.removeKeybind(windowsKeybind)
      KeybindManager.removeKeybind(EscapeKeybind)
    }
  }, [])

}