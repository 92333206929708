import EventEmitter from 'events'
import { io, Socket } from 'socket.io-client'
import { API_DOMAIN } from '../constants'
import { notifyDown, notifyUp } from './ServerDownManager'
import { SelfUserClass } from './SelfUserClass'

export class SocketManager extends EventEmitter {
  _socket!: Socket
  ready: boolean = false
  failtime: number = 0
  connecting?: boolean
  silenceReconnect: boolean = false
  private static instance: SocketManager
  private constructor() {
    super()
    //   console.log('SocketManager constructor'))
    this.connect()
    // const socket=this._socket
    // socket.on('popup', function (msg) {
    // //   console.log('hello: ', msg))
    // })
    // socket.on('connection', function () {
    // //   console.log('client connected'))
    // })

    // socket.on('connect_error', function (err) {
    // //   console.log('client connect_error: ', err))
    // })

    // socket.on('connect_timeout', function (err) {
    // //   console.log('client connect_timeout: ', err))
    // })
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new SocketManager()
    }
    return this.instance
  }
  disconnect() {
    this._socket.disconnect()
  }
  connect() {
    this._socket = io(API_DOMAIN, {
      auth: {
        token: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    this.connecting = true
    this._socket.on('connect', () => {
      this.ready = true
      this.connecting = false
      if (!this.silenceReconnect) notifyUp()
      else this.silenceReconnect = false
      SelfUserClass.getInstance().fetch()
      console.log('SOCKET UP')
    })
    this._socket.on('disconnect', () => {
      this.ready = false
      console.log('SOCKET DOWN')
      if (!this.silenceReconnect) notifyDown()
    })
    this._socket.onAny(this.emit.bind(this))
    // when socket fails to connect, it will try to reconnect
    // this._socket.on('reconnect', () => {
    this._socket.on('connect_failed', () => {
      console.log('SOCKET FAILED TO CONNECT')
      notifyDown()
      setTimeout(() => {
        this._socket.connect()
      }, Math.min(500 * 1.2 ** this.failtime, 10000))
    })
  }
  async reconnect() {
    this.silenceReconnect = true
    this.disconnect()

    while (this.connecting) {
      await new Promise(resolve => setTimeout(resolve, 10))
    }
    this.connect()
  }
  get socket() {
    return this._socket
  }
}
