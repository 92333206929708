import { useRouter } from 'next/router'
import { usePrimaryCommunity } from '../../../../utils/hooks/community/usePrimaryCommunity'
import { useUserColors } from '../../../../utils/hooks/customization/colors'
import { SidebarModuleType } from '../../../../utils/types/moduleTypes'

export const ModuleButtonPopper = (props: { module: SidebarModuleType }) => {
  const router = useRouter()
  const community = usePrimaryCommunity()
  const color = useUserColors()
  const { module } = props

  const onPage = module.url.toLowerCase() === router.asPath.toLowerCase()
  return (
    <div
      className={`w-10 h-10 relative hover:bg-opacity-50 transition-all rounded-full cursor-pointer group  p-4 dark:text-gray-300 `}
    >
      {/* <div
        className={`absolute top-0 left-0 w-full h-full ${
          onPage && `bg-${color} bg-opacity-30 group-hover:bg-opacity-70 scale-100`
        } bg-${color} scale-0 group-hover:scale-100 transition-all rounded-xl bg-opacity-40`}
      ></div> */}
      <div
        className={`z-20 absolute top-0 left-0 p-2 w-full h-full hover:text-${color} hover:text-opacity-90 dark:hover:bg-gray-700 hover:bg-gray-100 ${onPage && `text-${color} text-opacity-70`} hover:shadow-md rounded-xl transition-all duration-300`}
      >
        {module.icon}
      </div>
      <div
        className={`lg:hidden absolute top-1/2 right-0 w-max pointer-events-none translate-x-[calc(100%+2rem)] -translate-y-1/2 bg-gray-900 text-gray-50 p-2 rounded-lg scale-0 group-hover:scale-100 transition-transform origin-left`}
      >
        {module.name}
      </div>
    </div>
  )
}
