export const meshifyColor = (color: string) => {
  const colorOptions = [
    'red-500',
    'orange-500',
    'amber-500',
    'yellow-500',
    'lime-500',
    'green-500',
    'teal-500',
    'cyan-500',
    'sky-500',
    'blue-500',
    'indigo-500',
    'violet-500',
    'purple-500',
    'fuchsia-500',
    'pink-500',
    'rose-500',
  ]
  const colorIndex = colorOptions.indexOf(color)
  const meshArray = [
    {
      backgroundColor: `hsla(359,100%,72%,1)`,
      backgroundImage: `radial-gradient(at 33% 27%, hsla(306,74%,63%,1) 0px, transparent 50%),
      radial-gradient(at 76% 32%, hsla(22,81%,60%,1) 0px, transparent 50%),
      radial-gradient(at 95% 48%, hsla(270,67%,76%,1) 0px, transparent 50%),
      radial-gradient(at 96% 38%, hsla(24,100%,66%,1) 0px, transparent 50%),
      radial-gradient(at 15% 78%, hsla(0,100%,73%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `hsla(16,100%,80%,1)`,
      backgroundImage: `radial-gradient(at 99% 70%, hsla(36,100%,56%,1) 0px, transparent 50%),
      radial-gradient(at 22% 64%, hsla(4,100%,71%,1) 0px, transparent 50%),
      radial-gradient(at 78% 78%, hsla(144,100%,83%,1) 0px, transparent 50%),
      radial-gradient(at 57% 85%, hsla(24,100%,50%,1) 0px, transparent 50%),
      radial-gradient(at 58% 52%, hsla(0,100%,60%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `hsla(16,100%,80%,1)`,
      backgroundImage: `radial-gradient(at 99% 70%, hsla(36,100%,56%,1) 0px, transparent 50%),
      radial-gradient(at 22% 64%, hsla(4,100%,71%,1) 0px, transparent 50%),
      radial-gradient(at 78% 78%, hsla(144,100%,83%,1) 0px, transparent 50%),
      radial-gradient(at 57% 85%, hsla(24,100%,50%,1) 0px, transparent 50%),
      radial-gradient(at 58% 52%, hsla(0,100%,60%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `hsla(42,100%,68%,1)`,
      backgroundImage: `radial-gradient(at 66% 6%, hsla(24,90%,72%,1) 0px, transparent 50%),
      radial-gradient(at 45% 65%, hsla(24,95%,65%,1) 0px, transparent 50%),
      radial-gradient(at 23% 15%, hsla(86,61%,78%,1) 0px, transparent 50%),
      radial-gradient(at 81% 44%, hsla(0,100%,71%,1) 0px, transparent 50%),
      radial-gradient(at 99% 54%, hsla(54,100%,50%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `#a3ff99`,
      backgroundImage: `radial-gradient(at 78% 7%, hsla(151,90%,76%,1) 0px, transparent 50%),
      radial-gradient(at 5% 64%, hsla(166,97%,67%,1) 0px, transparent 50%),
      radial-gradient(at 55% 97%, hsla(55,82%,78%,1) 0px, transparent 50%),
      radial-gradient(at 76% 31%, hsla(268,59%,67%,1) 0px, transparent 50%),
      radial-gradient(at 42% 85%, hsla(311,100%,72%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `#a3ff99`,
      backgroundImage: `radial-gradient(at 78% 7%, hsla(151,90%,76%,1) 0px, transparent 50%),
      radial-gradient(at 5% 64%, hsla(166,97%,67%,1) 0px, transparent 50%),
      radial-gradient(at 55% 97%, hsla(55,82%,78%,1) 0px, transparent 50%),
      radial-gradient(at 76% 31%, hsla(268,59%,67%,1) 0px, transparent 50%),
      radial-gradient(at 42% 85%, hsla(311,100%,72%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `#a3ff99`,
      backgroundImage: `radial-gradient(at 78% 7%, hsla(151,90%,76%,1) 0px, transparent 50%),
      radial-gradient(at 5% 64%, hsla(166,97%,67%,1) 0px, transparent 50%),
      radial-gradient(at 55% 97%, hsla(55,82%,78%,1) 0px, transparent 50%),
      radial-gradient(at 76% 31%, hsla(268,59%,67%,1) 0px, transparent 50%),
      radial-gradient(at 42% 85%, hsla(311,100%,72%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `#99f3ff`,
      backgroundImage: `radial-gradient(at 56% 82%, hsla(143,100%,74%,0.85) 0px, transparent 50%),
      radial-gradient(at 83% 86%, hsla(212,94%,64%,1) 0px, transparent 50%),
      radial-gradient(at 23% 90%, hsla(217,70%,63%,0.53) 0px, transparent 50%),
      radial-gradient(at 81% 51%, hsla(32,77%,70%,0.51) 0px, transparent 50%),
      radial-gradient(at 23% 80%, hsla(285,61%,74%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `#99f3ff`,
      backgroundImage: `radial-gradient(at 56% 82%, hsla(143,100%,74%,0.85) 0px, transparent 50%),
      radial-gradient(at 83% 86%, hsla(212,94%,64%,1) 0px, transparent 50%),
      radial-gradient(at 23% 90%, hsla(217,70%,63%,0.53) 0px, transparent 50%),
      radial-gradient(at 81% 51%, hsla(32,77%,70%,0.51) 0px, transparent 50%),
      radial-gradient(at 23% 80%, hsla(285,61%,74%,1) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `#99f3ff`,
      backgroundImage: `radial-gradient(at 56% 82%, hsla(143,100%,74%,0.85) 0px, transparent 50%),
      radial-gradient(at 83% 86%, hsla(212,94%,64%,1) 0px, transparent 50%),
      radial-gradient(at 23% 90%, hsla(217,70%,63%,0.53) 0px, transparent 50%),
      radial-gradient(at 81% 51%, hsla(32,77%,70%,0.51) 0px, transparent 50%),
      radial-gradient(at 23% 80%, hsla(285,61%,74%,1) 0px, transparent 50%)`,
    },

    {
      backgroundColor: `hsla(259,100%,80%,1)`,
      backgroundImage: `radial-gradient(at 59% 32%, hsla(145,75%,74%,0.85) 0px, transparent 50%),
      radial-gradient(at 54% 66%, hsla(306,63%,55%,0.66) 0px, transparent 50%),
      radial-gradient(at 11% 93%, hsla(237,69%,64%,0.65) 0px, transparent 50%),
      radial-gradient(at 38% 88%, hsla(273,75%,73%,0.62) 0px, transparent 50%),
      radial-gradient(at 16% 16%, hsla(155,94%,60%,0.59) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `hsla(259,100%,80%,1)`,
      backgroundImage: `radial-gradient(at 59% 32%, hsla(145,75%,74%,0.85) 0px, transparent 50%),
      radial-gradient(at 54% 66%, hsla(306,63%,55%,0.66) 0px, transparent 50%),
      radial-gradient(at 11% 93%, hsla(237,69%,64%,0.65) 0px, transparent 50%),
      radial-gradient(at 38% 88%, hsla(273,75%,73%,0.62) 0px, transparent 50%),
      radial-gradient(at 16% 16%, hsla(155,94%,60%,0.59) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `hsla(259,100%,80%,1)`,
      backgroundImage: `radial-gradient(at 59% 32%, hsla(145,75%,74%,0.85) 0px, transparent 50%),
      radial-gradient(at 54% 66%, hsla(306,63%,55%,0.66) 0px, transparent 50%),
      radial-gradient(at 11% 93%, hsla(237,69%,64%,0.65) 0px, transparent 50%),
      radial-gradient(at 38% 88%, hsla(273,75%,73%,0.62) 0px, transparent 50%),
      radial-gradient(at 16% 16%, hsla(155,94%,60%,0.59) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `hsla(259,100%,80%,1)`,
      backgroundImage: `radial-gradient(at 59% 32%, hsla(145,75%,74%,0.85) 0px, transparent 50%),
      radial-gradient(at 54% 66%, hsla(306,63%,55%,0.66) 0px, transparent 50%),
      radial-gradient(at 11% 93%, hsla(237,69%,64%,0.65) 0px, transparent 50%),
      radial-gradient(at 38% 88%, hsla(273,75%,73%,0.62) 0px, transparent 50%),
      radial-gradient(at 16% 16%, hsla(155,94%,60%,0.59) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `hsla(298,100%,80%,1)`,
      backgroundImage: `radial-gradient(at 14% 94%, hsla(264,89%,75%,1) 0px, transparent 50%),
  radial-gradient(at 31% 32%, hsla(306,100%,72%,0.78) 0px, transparent 50%),
  radial-gradient(at 49% 62%, hsla(237,94%,67%,0.65) 0px, transparent 50%),
  radial-gradient(at 1% 33%, hsla(0,75%,73%,0.62) 0px, transparent 50%),
  radial-gradient(at 56% 33%, hsla(28,94%,60%,0.59) 0px, transparent 50%)`,
    },
    {
      backgroundColor: `hsla(359,100%,72%,1)`,
      backgroundImage: `radial-gradient(at 33% 27%, hsla(306,74%,63%,1) 0px, transparent 50%),
      radial-gradient(at 76% 32%, hsla(22,81%,60%,1) 0px, transparent 50%),
      radial-gradient(at 95% 48%, hsla(270,67%,76%,1) 0px, transparent 50%),
      radial-gradient(at 96% 38%, hsla(24,100%,66%,1) 0px, transparent 50%),
      radial-gradient(at 15% 78%, hsla(0,100%,73%,1) 0px, transparent 50%)`,
    },
  ]
  return meshArray[colorIndex]
}
